import React from "react";
import "./App.css";
import AVAILABLE_ITEMS from "../resources/data"
import {Item} from "../model/Item";
import QRCode from "react-qr-code";
import MetaData from "../util/MetaData";

type PropertyType = {};

type StateType = {
    basket: Map<string, { item: Item, count: number }>
    currentCategory: string | null
};

const createInitialState = () => {
    return {
        basket: AVAILABLE_ITEMS.flatMap(category => category.items)
            .reduce((map, item) => {
                map.set(item.code, {item, count: 0});
                return map;
            }, new Map<string, { item: Item, count: number }>()),
        currentCategory: null
    }
}

class App extends React.Component<PropertyType, StateType> {

    state = createInitialState();

    addItem(code: string) {
        const {basket} = this.state;
        const entry = basket.get(code);
        if (entry) {
            entry.count++;
            this.setState({basket});
        }
    }

    subtractItem(code: string) {
        const {basket} = this.state;
        const entry = basket.get(code);
        if (entry && entry.count > 0) {
            entry.count--;
            this.setState({basket});
        }
    }

    reset() {
        this.setState(createInitialState());
    }

    save() {
        //TODO: save
        this.reset();
    }

    render() {
        const {basket, currentCategory} = this.state;
        const items = Array.from(basket.values());
        const {total, numItems} = items.reduce((prev, curr) => ({
            total: prev.total + (curr.count * curr.item.price),
            numItems: prev.numItems + curr.count
        }), {total: 0, numItems: 0});
        return (
            <div className="App">
                <div className="top">
                    <button className="reset" onClick={() => this.reset()}>Rensa</button>
                    <span className="total">{total} kr</span>
                    <button className="save" onClick={() => this.save()}>Spara</button>
                </div>
                <QRCode
                    value={`https://app.swish.nu/1/p/sw/?sw=+46767608998&amt=${total}&cur=SEK&msg=After%20ski&src=qr`}
                    size={200}
                    fgColor={total > 0 ? "#000000" : "#FFFFFF"}
                />
                {numItems > 0 && <div className="center">
                    <table>
                        <tbody>
                        {items.map(({item, count}, index) => {
                            return count > 0 && <tr key={index}>
                                <td>{item.name}</td>
                                <td>{count} st</td>
                                <td>x</td>
                                <td>{item.price} kr</td>
                                <td>=</td>
                                <td>{count * item.price} kr</td>
                                <td>
                                    <button onClick={() => this.subtractItem(item.code)}>–</button>
                                    <button onClick={() => this.addItem(item.code)}>+</button>
                                </td>
                            </tr>;
                        })}
                        </tbody>
                    </table>
                </div>}
                <div className="center">
                    {currentCategory === null && <div className="categories">
                        {AVAILABLE_ITEMS.map(({name}, index) => <React.Fragment key={index}>
                            <button onClick={() => this.setState({currentCategory: name})}>
                                <span>{name}</span>
                            </button>
                        </React.Fragment>)}
                    </div>}
                    {currentCategory !== null && <div className="items">
                        {AVAILABLE_ITEMS.find(({name}) => name === currentCategory)!.items
                            .map((item, index) => <React.Fragment key={index}>
                                <button
                                    onClick={() => {
                                        this.addItem(item.code);
                                        this.setState({currentCategory: null});
                                    }}>
                                    <span>{item.name}<br/>{item.price} kr</span>
                                </button>
                            </React.Fragment>)}
                        <div>
                            <button onClick={() => this.setState({currentCategory: null})}>
                                <span>Tillbaka</span>
                            </button>
                        </div>
                    </div>}
                </div>
                <footer>
                    Version {MetaData.getVersion()} &copy; {MetaData.getBuildYear()}
                    <span><br/>Commit {MetaData.getCommitHash()}</span>
                    <br/>
                    Powered by LoPoBo
                </footer>
            </div>
        );
    }
}

export default App;
